<template>
  <chip class="exclusive-submission-status" :class="getStatusClass()">
    {{ status.name }}
  </chip>
</template>

<script>
import { exclusiveSubmissionStatus as STATUS } from "@/enums";
import Chip from "@/components/GlobalComponents/Chip";

export default {
  components: { Chip },
  mixins: [],
  props: {
    status: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      STATUS
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    getStatusClass() {
      switch (Number(this.status.id)) {
        case STATUS.PENDING:
          return "bg-warn fg-white";
        case STATUS.APPROVED:
          return "bg-success fg-white";
        case STATUS.REJECTED:
          return "bg-danger fg-white";
        default:
          return "";
      }
    }
  }
};
</script>

<style lang="scss"></style>
