<template>
  <div class="fd-chip" :class="{ outline: outline }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    outline: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.fd-chip {
  background: #f0f0f0;
  color: #656565;
  border-radius: 50em;
  padding: 4px 16px;
  font-size: 12px;
  width: fit-content;
  height: fit-content;
  display: inline-block;
  &.active {
    background: $color-main;
    color: white;
    font-weight: bold;
  }
  &.outline {
    border: solid 1px #5b5b5b;
    background: transparent;
  }
}
</style>
